import React from "react"

import Header from "./header"
import Footer from "./footer"
import Sidebar from "./sidebar"

const Layout = props => {
  return (
    <div className="flex flex-col h-screen max-h-full max-w-full mx-auto  ">
      <Header />
      <div className="flex-grow  max-w-5xl mx-auto  ">
        <Sidebar />
      </div>
      <div className="mb-20 ">{props.children}</div>

      <Footer />
    </div>
  )
}

export default Layout

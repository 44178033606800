import React from "react"
import { Link } from "gatsby"

import Background from "../images/BGimage.svg"

const Sidebar = () => {
  return (
    // <div class="flex h-screen justify-center items-center">
    <>
      <div
        className="mt-32 ml-6 fixed left-0  invisible xl:visible "
        // style={{ backgroundImage: `url(${Background})` }}
      >
        <ul className="p-2 flex flex-col w-full text-2xl text-gray-600 font-ubuntu">
          <li className="my-px">
            <Link to="/">Home</Link>
          </li>
          <li className="my-px">
            <Link to="/monochrome/">Monochrome</Link>
          </li>
          <li className="my-px">
            <Link to="/metro/">Metro</Link>
          </li>
          <li className="my-px">
            <Link to="/color/">Color</Link>
          </li>
          <li className="my-px">
            <Link to="/studio/">Studio</Link>
          </li>
          <li className="my-px">
            <Link to="/bio/">Bio</Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Sidebar

import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const title = "NINNA MOSS"
const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <>
      <div className="grid grid-cols-6 gap-4 py-8">
        <div className=" col-start-2 col-span-4 border-b-2 border-gray-300 ">
          <h1 className="text-3xl font-catamaran text-gray-500 text-center">
            {/* {data.site.siteMetadata.title} */}
            {title}
          </h1>
        </div>
      </div>
    </>
  )
}

export default Header

import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <>
      <footer>
        <div className=" flex flex-row   fixed bottom-0 w-screen justify-center border-t-2 bg-teal-700  xl:invisible">
          <ul className=" mb-4 flex  flex-wrap justify-center text-white">
            <li className="px-2">
              <Link to="/">Home</Link>
            </li>
            <li className="px-2">
              <Link to="/monochrome/">Monochrome</Link>
            </li>
            <li className="px-2">
              <Link to="/metro/">Metro</Link>
            </li>
            <li className="px-2">
              <Link to="/color/">Color</Link>
            </li>
            <li className="px-2">
              <Link to="/studio/">Studio</Link>
            </li>
            <li className="px-2">
              <Link to="/bio/">Bio</Link>
            </li>
          </ul>
        </div>
      </footer>
      <div className="flex flex-row bottom-0 invisible sm:visible">
        <p>Created by Irakli Dzigrashvili, © 2020</p>
      </div>
    </>
  )
}

export default Footer
